<template>
  <div
    class="px-5 shadow-lg"
    :class="{ 'mb-4 rounded-md py-4': !slim, 'py-2': slim }"
  >
    <slot name="default">
      <div class="text-center">
        <p v-if="title" class="font-semibold">{{ title }}</p>
        <p v-if="subtitle" class="mt-1 text-sm">{{ subtitle }}</p>
        <slot name="content" />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
export interface Props {
  title?: string;
  subtitle?: string;
  slim?: boolean;
}

const props = withDefaults(defineProps<Props>(), { slim: false });

const { title, subtitle, slim } = toRefs(props);
</script>
